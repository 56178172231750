import React, {useEffect, useRef, useState} from "react";
import {Alert, Container, Box, Button, SelectChangeEvent} from "@mui/material";
import {GRID_CHECKBOX_SELECTION_COL_DEF, GridColumnVisibilityModel, GridFilterModel, GridRenderCellParams, GridSortModel} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import {Banner} from "../banner/banner.component";
import {useEsoInfo, useEsoInfoCount} from "./eso.hooks";
import {CustomDataGrid} from "../dataGrid/dataGrid.component";
import {columnSearchCacheGet, columnSearchCacheSet, searchCacheGet, searchCacheSet} from "../dataGrid/searchCache";

interface gridProps {
    id: string | undefined;
    searchCacheKey: string;
    useColumnFilters?: boolean;
    blendToTabs?: boolean;
}

const initialVisibilityModel = {
    object_id: false,
    rct_serial: false,
    module_id: false,
    manufacturing_date: false,
    manufacturing_time: false,
    part_number: false,
    operator_id: false,
    scan_station: false,
};

export function EsoGrid({id, searchCacheKey, useColumnFilters, blendToTabs}: gridProps): JSX.Element {
    const [paginationModel, setPaginationModel] = useState({page: 0, pageSize: 10});
    const [search, setSearch] = useState<any[] | undefined>(searchCacheGet(searchCacheKey));
    const [visibilityModel, setVisibilityModel] = useState<any>(initialVisibilityModel);
    const [sortModel, setSortModel] = useState<GridSortModel | undefined>(undefined);
    const [columnSearch, setColumnSearch] = useState(columnSearchCacheGet(searchCacheKey));
    const [columnSearchParams, setColumnSearchParams] = useState(columnSearchCacheGet(searchCacheKey));

    const filter = useRef<any[] | undefined>(undefined);
    const rowCount = useEsoInfoCount(search, id, columnSearchParams);
    const esoInfo = useEsoInfo(paginationModel.pageSize, paginationModel.page, search, id, sortModel, columnSearchParams);
    const navigate = useNavigate();

    const exportInfo = useEsoInfo(0, 0, search, id, sortModel, columnSearchParams);

    const columnConfig = [
        {...GRID_CHECKBOX_SELECTION_COL_DEF, width: 60, hideable: false},
        {field: "object_id", headerName: "ID", minWidth: 50, editable: false, hideable: false},
        {field: "name", headerName: "Name", minWidth: 110, flex: 1, editable: false},
        {field: "type", headerName: "Type", minWidth: 140, flex: 1, editable: false},
        {field: "vendor", headerName: "Vendor", minWidth: 140, flex: 1, editable: false},
        {field: "model", headerName: "Model", minWidth: 140, flex: 1, editable: false},
        {field: "part_serial", headerName: "Serial Number", minWidth: 140, flex: 1, editable: false},
        {field: "nameplate_serial", headerName: "Nameplate Serial", minWidth: 150, flex: 1, editable: false},
        {field: "project_name", headerName: "Project Name", minWidth: 180, flex: 1, editable: false},
        {field: "part_number", headerName: "Part Number", minWidth: 130, flex: 1, editable: false},
        {field: "rct_serial", headerName: "Supplier Serial", minWidth: 140, flex: 1, editable: false},
        {field: "module_id", headerName: "Module ID", minWidth: 120, flex: 1, editable: false},
        {field: "manufacturing_date", headerName: "Manufacturing Date", minWidth: 170, flex: 1, editable: false},
        {field: "manufacturing_time", headerName: "Manufacturing Time", minWidth: 170, flex: 1, editable: false},
        {field: "operator_id", headerName: "Operator ID", minWidth: 120, flex: 1, editable: false},
        {field: "scan_station", headerName: "Scan Station", minWidth: 140, flex: 1, editable: false},
        {field: "actions", headerName: "Actions", minWidth: 95, flex: 0, editable: false, hideable: false, sortable: false, renderCell: (params: GridRenderCellParams) => {
                const onClick = (event: React.MouseEvent<HTMLElement>): void => {
                    event.stopPropagation();

                    const objectId = params.row.object_id;
                    const moduleId = params.row.module_id;
                    navigate({pathname: `/eso_data/${objectId}+${moduleId}`})
                }

                return(
                    <Button
                        style={{ float: "right" }}
                        variant="text"
                        onClick={onClick}
                        disableRipple>
                        View
                    </Button>
                );
            }
        }
    ]

    useEffect(() => {
        searchCacheSet(searchCacheKey, search);
        columnSearchCacheSet(searchCacheKey, columnSearch);
    })

    useEffect(() => {
        const timer = setTimeout(() => setColumnSearchParams(columnSearch), 500);

        return () => {
            clearTimeout(timer);
        };
    }, [columnSearch])

    useEffect(() => {
        setPaginationModel({page: 0, pageSize: paginationModel.pageSize})
    },[columnSearchParams])

    const columnSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newObject: {[k: string]: any} = {};

        Object.keys(columnSearch).forEach((key) => {
            newObject[key] = columnSearch[key];
        });

        newObject[event.target.id] = event.target.value;

        setColumnSearch(newObject);
    }

    const paginationModelChanged = (newPaginationModel: {page: number, pageSize: number}): void => {
        setPaginationModel(newPaginationModel);
    };

    const filterModelChanged = (newModel: GridFilterModel): void => {
        filter.current = newModel.quickFilterValues;
        if(filter.current === undefined || filter.current?.length === 0) {
            // If search bar was cleared, return to the unfiltered data
            setSearch(filter.current);
        }
    };

    const visibilityModelChanged = (newVisibilityModel: GridColumnVisibilityModel): void => {
        setVisibilityModel(newVisibilityModel)
    }

    return (
        <Box>
            {esoInfo.isError && (
                <Alert severity="error" sx={{ mb: 2 }}>Something went wrong while loading backend status</Alert>
            )}
            {!esoInfo.isError && (
                <Box>
                    <Box style={{height: 670, width: "100%"}}>
                        <CustomDataGrid
                            blendToTabs={blendToTabs}
                            allowSearch={false}
                            columnVisibilityModel={visibilityModel}
                            columnVisibilityModelChanged={visibilityModelChanged}
                            columnConfig={columnConfig}
                            itemInfo={esoInfo.data}
                            allowMultiSelect
                            paginationModel={paginationModel}
                            paginationModelChanged={paginationModelChanged}
                            rowCount={rowCount.data ?? 0}
                            filterModelChanged={filterModelChanged}
                            searchButtonClick={() => {setSearch(filter.current)}}
                            searchEnterKey={() => {setSearch(filter.current)}}
                            initSearch={search}
                            isLoading={esoInfo.isLoading}
                            useFilterHeader={useColumnFilters}
                            columnFilter={columnSearch}
                            onPageChanged={(event, page) =>
                                setPaginationModel({
                                    page: page - 1,
                                    pageSize: paginationModel.pageSize
                                })
                            }
                            onPageSizeChanged={(event: SelectChangeEvent<number>) => {
                                setPaginationModel({
                                    page: paginationModel.page,
                                    pageSize: event.target.value as number
                                })
                            }}
                            sortModelChanged={(model) => {
                                setSortModel(model);
                            }}
                            onColumnSearchChanged={columnSearchOnChange}
                            exportEnabled
                            exportDataQuery={exportInfo}
                            exportName="ES&O Asset Data"
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export function Eso(): JSX.Element {
    return (
        <Box>
            <Banner firstLine="ES&O Asset Data" secondLine="Search" gridBanner/>
            <Container maxWidth="xl">
                <EsoGrid id={undefined} searchCacheKey="eso" useColumnFilters/>
            </Container>
        </Box>
    );
}
