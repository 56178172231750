import {useQuery, UseQueryResult} from "@tanstack/react-query";
import axios from "axios";
import {GridSortModel} from "@mui/x-data-grid";
import {PlantnetInfo} from "./plantnet.types";
import {useAuth} from "../auth/auth.hooks";

export const usePlantnetInfo = (
    limit: number,
    page: number,
    search: string[] | undefined,
    id: string | undefined,
    sort: GridSortModel | undefined,
    columnFilter: object | undefined
): UseQueryResult<PlantnetInfo[]> => {
    const { accessToken, config } = useAuth();

    let sortField: string | undefined;
    let sortDir: number | undefined;

    if(sort !== undefined && sort.length > 0) {
        sortField = sort[0].field;
        sortDir = sort[0].sort === "asc" ? 1 : -1;
    }

    return useQuery({
        queryKey: ['plantnet_m', { 'limit': limit, 'page': page, 'search': search, 'id': id, 'sort': sort }, columnFilter],
        queryFn:() =>
            axios.get<PlantnetInfo[]>(
                `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/plantnet`,
                { params: {
                        limit,
                        page,
                        ...(id !== undefined ? { inst_id: id } : {}),
                        ...(search !== undefined ? { search: String(search) } : {}),
                        ...(sortField !== undefined ? { sort: sortField } : {}),
                        ...(sortDir !== undefined ? { sort_dir: sortDir } : {}),
                        ...((columnFilter !== undefined && Object.keys(columnFilter).length > 0) ? {column_filter: columnFilter} : {})
                    }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
};

export const usePlantnetItem = (id: string): UseQueryResult<PlantnetInfo[]> => {
    const { accessToken, config } = useAuth();

    return useQuery({
        queryKey: ['plantnet_s', { 'id': id }],
        queryFn:() =>
            axios.get<PlantnetInfo[]>(
                `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/plantnet`,
                { params: {
                        object_id: id
                    }, headers: { Authorization: `Bearer ${accessToken}` } }
            )
                .then((response) => response.data),
        
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
};

export const usePlantnetInfoCount = (
    search: string[] | undefined,
    id: string | undefined,
    columnFilter: object | undefined
): UseQueryResult<number> => {
    const { accessToken, config } = useAuth();

    return useQuery({
        queryKey: ['plantnet_rows', { 'search': search, 'id': id }, columnFilter],
        queryFn:() =>
            axios
                .get<number>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/plantnet/rows`,
                    { params: {
                            ...(search !== undefined ? { search: String(search) } : {}),
                            ...(id !== undefined ? { inst_id: id } : {}),
                            ...((columnFilter !== undefined && Object.keys(columnFilter).length > 0) ? {column_filter: columnFilter} : {})
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}
