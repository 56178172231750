import {useQuery, UseQueryResult} from "@tanstack/react-query";
import axios from "axios";
import {useAuth} from "../auth/auth.hooks";
import {HistoryInfo} from "./history.types";
import {sourceTypeDatas, sourceTypes} from "../misc";


export const useHistory = (source: string, id: string): UseQueryResult<HistoryInfo> => {
    const { accessToken, config } = useAuth();

    return useQuery({
        queryKey: ['history', {'source': source, 'id': id}],
        queryFn:() =>
            axios
                .get<HistoryInfo>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/${source}/history/${id}?remap=true`,
                    { headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}

export const useRevisionHistory = (type: sourceTypes, id: string): UseQueryResult<HistoryInfo> => {
    const { accessToken, config } = useAuth();

    // If in add asset mode, don't try to search for history
    // React for some reason wants to call the same amount of hooks every render,
    // so we need to do this dummy query so React won't throw a tantrum.
    if(id === "add") {
        return useQuery({queryKey: ['history', type, { 'id': id }], queryFn:() => [], });
    }

    return useQuery({
        queryKey: ['history', type, { 'id': id }],
        queryFn:() =>
            axios
                .get<HistoryInfo>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/assets/${sourceTypeDatas[type].api}/history/${id}`,
                    { headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}
