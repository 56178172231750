import axios from "axios";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {GridSortModel} from "@mui/x-data-grid";
import {useAuth} from "../auth/auth.hooks";
import {AssetGroupDetails, AssetGroupsInfo, DropDownItem} from "./assetGroups.types";


export const useAssetGroups = (
    limit: number,
    page: number,
    search: string[] | undefined,
    sort: GridSortModel | undefined,
    columnFilter: object | undefined
): UseQueryResult<AssetGroupsInfo[]> => {
    const { accessToken, config } = useAuth();
    let sortField: string | undefined;
    let sortDir: number | undefined;

    if(sort !== undefined && sort.length > 0) {
        sortField = sort[0].field;
        sortDir = sort[0].sort === "asc" ? 1 : -1;
    }

    return useQuery({
        queryKey: ['asset_groups', { 'limit': limit, 'page': page, 'search': search, 'sort': sort }, columnFilter],
        queryFn:() =>
            axios
                .get<AssetGroupsInfo[]>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/asset_groups/assets/asset_groups`,
                    { params: {
                            limit,
                            page,
                            ...(search !== undefined ? { search: String(search) } : {}),
                            ...(sortField !== undefined ? { sort: sortField } : {}),
                            ...(sortDir !== undefined ? { sort_dir: sortDir } : {}),
                            ...((columnFilter !== undefined && Object.keys(columnFilter).length > 0) ? {column_filter: columnFilter} : {})
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        
        staleTime: 60000,
        refetchOnWindowFocus: true,
        enabled: Boolean(accessToken),
    })
}

export const useAssetGroupDetails = (
    limit: number,
    page: number,
    groupId: string,
    search: string[] | undefined,
    sort: GridSortModel | undefined,
    columnFilter: object | undefined
): UseQueryResult<AssetGroupDetails[]> => {
    const { accessToken, config } = useAuth();
    let sortField: string | undefined;
    let sortDir: number | undefined;

    if(sort !== undefined && sort.length > 0) {
        sortField = sort[0].field;
        sortDir = sort[0].sort === "asc" ? 1 : -1;
    }

    return useQuery({
        queryKey: ['asset_group_details', { 'limit': limit, 'page': page, 'id': groupId, 'search': search, 'sort': sort }, columnFilter],
        queryFn:() =>
            axios
                .get<AssetGroupDetails[]>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/asset_groups/assets/asset_group_details`,
                    { params: {
                            limit,
                            page,
                            ...(search !== undefined ? { search: String(search) } : {}),
                            ...(sortField !== undefined ? { sort: sortField } : {}),
                            ...(sortDir !== undefined ? { sort_dir: sortDir } : {}),
                            ...((columnFilter !== undefined && Object.keys(columnFilter).length > 0) ? {column_filter: columnFilter} : {}),
                            group_id: groupId
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        })
}

export const useAssetDetails = (groupId: string, assetId: number): UseQueryResult<AssetGroupDetails> => {
    const { accessToken, config } = useAuth();

    return useQuery({
        queryKey: ['asset_group_detail', { 'groupId': groupId, 'assetId': assetId }],
        queryFn:() =>
            axios
                .get<AssetGroupDetails>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/asset_groups/assets/asset_details`,
                    { params: {
                            group_id: groupId,
                            asset_id: assetId
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        })
}


export const useProductDetails = (vendorId: number): UseQueryResult<DropDownItem[]> => {
    const { accessToken, config } = useAuth();

    return useQuery({
        queryKey: ['products_dd', {'vendorId': vendorId,}],
        queryFn:() =>
            axios
                .get<DropDownItem[]>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/asset_groups/assets/products_dd`,
                    { params: {
                        vendor_id: vendorId,
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        })
}

export const useVendorDetails = (searchValue: string): UseQueryResult<DropDownItem[]> => {
    const { accessToken, config } = useAuth();

    return useQuery({
        queryKey: ['vendors_dd', {'search': searchValue,}],
        queryFn:() =>
            axios
                .get<DropDownItem[]>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/asset_groups/assets/vendors_dd`,
                    { params: {
                        search: searchValue,
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        })
}

export const useAssetGroupCount = (
    search: string[] | undefined,
    columnFilter: object | undefined
): UseQueryResult<number> => {
    const { accessToken, config } = useAuth();

    return useQuery({
        queryKey: ['asset_groups_rows', { 'search': search }, columnFilter],
        queryFn:() =>
            axios
                .get<number>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/asset_groups/assets/asset_groups/rows`,
                    { params: {
                            ...(search !== undefined ? { search: String(search) } : {}),
                            ...((columnFilter !== undefined && Object.keys(columnFilter).length > 0) ? {column_filter: columnFilter} : {})
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}

export const useAssetGroupDetailsCount = (
    groupId: string,
    search: string[] | undefined,
    columnFilter: object | undefined
): UseQueryResult<number> => {
    const { accessToken, config } = useAuth();

    return useQuery({
        queryKey: ['asset_group_details_rows', { 'id': groupId, 'search': search }, columnFilter],
        queryFn:() =>
            axios
                .get<number>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/asset_groups/assets/asset_group_details/rows`,
                    { params: {
                            group_id: groupId,
                            ...(search !== undefined ? { search: String(search) } : {}),
                            ...((columnFilter !== undefined && Object.keys(columnFilter).length > 0) ? {column_filter: columnFilter} : {})
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}

export function postEditedCellData(groupId: string, id: number, data: AssetGroupDetails, auth: {accessToken: string, config: Record<string, unknown>}): Promise<any> {
    // NOTE: This function is not called every render, so we can't call hooks here, otherwise React will complain
    // The data from useAuth needs to be passed from parent that is called every render

    return axios.post(`${auth.config.REACT_APP_API_BASE_URL}/${auth.config.REACT_APP_ENVIRONMENT}/otam/asset_groups/assets/asset_group/edit`,
        {
            version: data.version,
            vendor_name: data.vendor_name,
            product_name: data.product_name,
            description: data.description,
            latest_patch: data.latest_patch,
            outage: data.outage,
            type: data.type,
            product_name_match: "",
            vendor_name_match: "",
            version_name_match: "",
            asset_id: id
        },
        {
            params: {
                group_id: groupId,
                asset_id: id,
            }, headers: { Authorization: `Bearer ${auth.accessToken}` }
        })
}

export function postNewCellData(groupId: string, data: AssetGroupDetails, auth: {accessToken: string, config: Record<string, unknown>}): Promise<any> {
    // NOTE: This function is not called every render, so we can't call hooks here, otherwise React will complain
    // The data from useAuth needs to be passed from parent that is called every render

    return axios.post(`${auth.config.REACT_APP_API_BASE_URL}/${auth.config.REACT_APP_ENVIRONMENT}/otam/asset_groups/assets/asset_group/add`,
        {
            version: data.version,
            vendor_name: data.vendor_name,
            product_name: data.product_name,
            description: data.description,
            latest_patch: data.latest_patch,
            outage: data.outage,
            type: data.type,
            product_name_match: "",
            vendor_name_match: "",
            version_name_match: "",
            asset_id: data.object_id
        },
        {
            params: {
                group_id: groupId,
            }, headers: { Authorization: `Bearer ${auth.accessToken}` }
        })
}
