import React, {createContext, Dispatch, FormEvent, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {Alert, Box, Container, Divider, Typography} from "@mui/material";
import {QueryClient, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import {Navigate} from "react-router-dom";
import {useAuth} from "../../auth/auth.hooks";
import {Banner} from "../../banner/banner.component";
import {sourceTypeDatas, sourceTypes} from "../../misc";
import {
    CancelButton,
    EditButton,
    InfoDatePickerPair,
    InfoFieldPair,
    InfoObjectDropdownPair,
    UploadStatusMessage
} from "../../viewPageCommonElements/viewPage.component";
import {useAssetDataItem} from "../assetData.hooks";
import {useRevisionHistory} from "../../history/history.hooks";
import {ParseRevisionData} from "../../history/history.misc";
import {AssetDataInfo, AssetDataVendor} from "../assetData.types";
import {FieldHistorySet} from "../../history/history.types";
import {GetString} from "../../../localization";
import {useVendorDetails} from "../../assetGroups/assetGroups.hooks";
import {DropDownItem} from "../../assetGroups/assetGroups.types";

export const withHooksHOC = (Component: React.ElementType) => function useHooks(props: any): JSX.Element {
    const {accessToken, config} = useAuth();
    const queryClient = useQueryClient();
    return <Component {...props} accessToken={accessToken} config={config} queryClient={queryClient}/>;
}

interface IHooksHOCProps {
    type: sourceTypes;
    accessToken: string;
    queryClient: QueryClient;
    config: Record<string, unknown>;
}

interface ViewAssetDetailsProps {
    type: sourceTypes;
    objectId: string;
}

interface InfoBoxProps {
    name: string;
    data: AssetDataInfo;
    content: InfoBoxDefinition[];
    source: string;
    objectId: string;
    historyChanges: FieldHistorySet[];
}

enum editFieldType {
    none = -1,
    textField = 0,
    vendorDropdown = 1,
    datePicker = 2,
}

// Data formatter is used to format field data to be shown in the page
// Data parser is used to format field data to be sent for edit or add asset
type InfoBoxDefinition = {
    field: string;
    subField: string;
    editable: boolean;
    mandatory: boolean;
    fieldType: editFieldType;
    dataFormatter: (data: any) => string;
    dataParser: (data: any) => any;
    [key: string]: string | boolean | any;
}

function defaultDataParser(data: any): any {
    return data;
}

function defaultDataFormatter(data: any): string {
    return data;
}

function epochToDateTime(data: number): string {
    return (new Date(data * 1000)).toDateString();
}

function dateTimeToEpoch(data: string): number {
    return Math.trunc(Date.parse(data) / 1000);
}

function getVendorName(data: AssetDataVendor): string {
    return data !== undefined ? data.name : "";
}

function getSourceType(data: string): string {
    let value = "-";

    Object.values(sourceTypeDatas).forEach((v) => {
        if(v.api === data) {
            value = v.name;
        }
    })

    return value
}

const infoBoxContent: InfoBoxDefinition[] = [
    {
        field: "installation_id",
        subField: "",
        editable: true,
        mandatory: false,
        fieldType: editFieldType.textField,
        dataFormatter: defaultDataFormatter,
        dataParser: defaultDataParser,
    },
    {
        field: "description",
        subField: "",
        editable: true,
        mandatory: false,
        fieldType: editFieldType.textField,
        dataFormatter: defaultDataFormatter,
        dataParser: defaultDataParser,
    },
    {
        field: "vendor",
        subField: "",
        editable: true,
        mandatory: true,
        fieldType: editFieldType.vendorDropdown,
        dataFormatter: getVendorName,
        dataParser: defaultDataParser,
    },
    {
        field: "model",
        subField: "name",
        editable: true,
        mandatory: false,
        fieldType: editFieldType.textField,
        dataFormatter: defaultDataFormatter,
        dataParser: defaultDataParser,
    },
    {
        field: "asset_type",
        subField: "",
        editable: false,
        mandatory: false,
        fieldType: editFieldType.textField,
        dataFormatter: defaultDataFormatter,
        dataParser: defaultDataParser,
    },
    {
        field: "mycyber_product_id",
        subField: "",
        editable: false,
        mandatory: false,
        fieldType: editFieldType.none,
        dataFormatter: defaultDataFormatter,
        dataParser: defaultDataParser,
    },
    {
        field: "mycyber_business_unit_id",
        subField: "",
        editable: false,
        mandatory: false,
        fieldType: editFieldType.none,
        dataFormatter: defaultDataFormatter,
        dataParser: defaultDataParser,
    },
    {
        field: "creation_data",
        subField: "source_type",
        editable: false,
        mandatory: false,
        fieldType: editFieldType.none,
        dataFormatter: getSourceType,
        dataParser: defaultDataParser,
    },
    {
        field: "creation_data",
        subField: "created_by",
        editable: false,
        mandatory: false,
        fieldType: editFieldType.textField,
        dataFormatter: defaultDataFormatter,
        dataParser: defaultDataParser,
    },
    {
        field: "creation_data",
        subField: "update_date",
        editable: false,
        mandatory: false,
        fieldType: editFieldType.datePicker,
        dataFormatter: epochToDateTime,
        dataParser: dateTimeToEpoch,
    },
]

const emptyAsset: AssetDataInfo = {
    object_id: "",
    installation_id: "",
    description: "",
    vendor: {
        name: "",
        id: -1,
    },
    model: {
        name: "",
    },
    asset_type: "",
    mycyber_product_id: -1,
    mycyber_business_unit_id: -1,
    creation_data: {
        created_by: "",
        created_date: -1,
        update_date: -1,
        source_type: "",
    },
};

interface ViewStateType {
    editing: {
        value: boolean;
        setValue: Dispatch<SetStateAction<boolean>>
    },
    updating: {
        value: boolean;
        setValue: Dispatch<SetStateAction<boolean>>
    },
    updateStatus: {
        value: string;
        setValue: Dispatch<SetStateAction<string>>
    },
    dataLoaded: {
        value: boolean;
        setValue: Dispatch<SetStateAction<boolean>>
    },
    vendor: {
        value: AssetDataVendor;
        setValue: Dispatch<SetStateAction<AssetDataVendor>>
    },
    status: {
        value: string;
        setValue: Dispatch<SetStateAction<string>>
    },
    initialData: {
        value: AssetDataInfo;
        setValue: Dispatch<SetStateAction<AssetDataInfo>>
    },
    objectId: {
        value: string;
        setValue: Dispatch<SetStateAction<string>>;
    }
}

const ViewState = createContext<ViewStateType | undefined>(undefined);

const useViewState = (): ViewStateType => {
    const context = useContext(ViewState);
    if(context === undefined) {
        throw new Error("ViewState context not found")
    }
    return context
}

function submitForm(
    objectId: string,
    type: sourceTypes,
    product: number,
    content: InfoBoxDefinition[],
    status: string,
    accessToken: string,
    config: Record<string, any>,
    queryClient: QueryClient,
    state: ViewStateType,
): void {
    if(!state.editing.value) {
        state.editing.setValue(true);
    }

    if(state.editing.value) {
        state.updating.setValue(true);
        state.updateStatus.setValue("updating");

        const form = document.getElementById("editForm") as HTMLFormElement;
        const data = Object.fromEntries(new FormData(form));

        const object = state.initialData.value;
        // eslint-disable-next-line prefer-destructuring
        const model = object.model;

        object.object_id = objectId;
        let mandatoryMissing = false;

        for(let i = 0; i < content.length; i += 1) {
            const key = content[i].field;

            if(content[i].editable) {
                // Vendor is an object, which can't be passed through FormData properly, so we need special handling for it
                // If we were to use the FormData value, we'd just get "[Object object]" string out from it...
                if(key === "vendor" && state.vendor !== undefined && state.vendor.value.id !== -1) {
                    object.vendor = state.vendor.value;
                }
                else if(content[i].subField === "") {
                    object[key] = content[i].dataParser(data[key]);
                }
                else if(key === "model") {
                    const sub = content[i].subField;
                    model[sub] = content[i].dataParser(data[`${key}_${sub}`]);
                }

                if(content[i].mandatory && (object[key] === undefined || object[key] === "" || object[key] === -1)) {
                    mandatoryMissing = true
                }
            }
        }

        if(mandatoryMissing) {
            state.updating.setValue(false);
            state.updateStatus.setValue("mandatory")
            return;
        }

        object.model = model;

        let postType = "edit";

        if(objectId === "add") {
            postType = "add";
            object.creation_data.source_type = sourceTypeDatas[type].api;

            if(type === sourceTypes.Psirt) {
                object.mycyber_product_id = product;
            }
        }

        const json = JSON.stringify(object);
        const conf = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        }

        axios.post(`${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/assets/${sourceTypeDatas[type].api}/${postType}`, json, conf)
            .then((response) => {
                if(response.status === 200) {
                    // Backend data has been updated, so local cache is no longer valid
                    queryClient.invalidateQueries({ queryKey: ['asset_data_s', type, { 'id': objectId }], exact: true});
                    queryClient.invalidateQueries({ queryKey: ['history', type, { 'id': objectId }]})
                }

                state.objectId.setValue(response.data.object_id);
                state.editing.setValue(false);
                state.updating.setValue(false);
                state.updateStatus.setValue(objectId === "add" ? "added" : "success");
            }).catch(() => {
                state.editing.setValue(false);
                state.updating.setValue(false);
                state.updateStatus.setValue("failed");
        })
    }
}

function getInfoBoxFields(
    data: AssetDataInfo,
    content: InfoBoxDefinition[],
    objectId: string,
    source: string,
    historyChanges: FieldHistorySet[],
): JSX.Element[] {
    const {editing, updating, vendor} = useViewState()

    const [vendorSearch, setVendorSearch] = useState("");
    const vendorData = useVendorDetails(vendorSearch);
    const vendors = vendorData.isSuccess ? vendorData.data : [];

    const items: JSX.Element[] = [];

    for(let i = 0; i < content.length; i += 1) {
        if(objectId === "add" && !content[i].editable) {
            // If in add mode, don't show fields that can't be edited
            // Some of these fields are initialized in the backend, so they have
            // dummy values

            // eslint-disable-next-line no-continue
            continue;
        }

        let value: string;
        let field: string;

        if(content[i].subField !== "") {
            const object: {[key: string]: any} = data[content[i].field] as {[key: string]: any}
            value = content[i].dataFormatter(object[content[i].subField])
            field = `${content[i].field}_${content[i].subField}`
        }
        else {
            value = content[i].dataFormatter(data[content[i].field])
            field = content[i].field;
        }

        switch(content[i].fieldType) {
            case editFieldType.vendorDropdown:
                items.push(
                    <InfoObjectDropdownPair
                        title={GetString(field)}
                        name={field}
                        key={field}
                        data={value}
                        dropdownOptions={vendors}
                        editing={editing.value}
                        updating={updating.value}
                        editable={content[i].editable}
                        loading={vendors.length === 0}
                        onChangeCallback={(v: AssetDataVendor) => {vendor.setValue(v)}}
                        onSearchChangeCallback={(s: string) => {
                            setVendorSearch(s)}
                        }
                        objectId={objectId}
                        source={source}
                        assetName={data.description}
                        historyData={historyChanges.find(x => x.name === content[i].field)}
                        mandatory={content[i].mandatory}
                    />
                );
                break;

            case editFieldType.datePicker:
                items.push(
                    <InfoDatePickerPair
                        title={GetString(field)}
                        name={field}
                        key={field}
                        data={value as unknown as Date}
                        views={["day", "month", "year"]}
                        editing={editing.value}
                        updating={updating.value}
                        editable={content[i].editable}
                        historyData={historyChanges.find(x => x.name === content[i].field)}
                        objectId={objectId}
                        source={source}
                        assetName={data.description}
                        mandatory={content[i].mandatory}
                    />
                );
                break;

            case editFieldType.textField:
            default:
                items.push(
                    <InfoFieldPair
                        title={GetString(field)}
                        name={field}
                        key={field}
                        data={value}
                        editing={editing.value}
                        updating={updating.value}
                        editable={content[i].editable}
                        historyData={historyChanges.find(x => x.name === content[i].field)}
                        objectId={objectId}
                        source={source}
                        assetName={data.description}
                        mandatory={content[i].mandatory}
                    />
                );
                break;
        }
    }

    return items;
}

function InfoBox({name, data, content, source, objectId, historyChanges}: InfoBoxProps): JSX.Element {
    return(
        <Box className="viewAssetBox" style={{float: "left", width: "100%", minWidth: "820px"}}>
            <Typography style={{color: "#000000", fontWeight: "bold", fontSize: "larger"}}>
                {name}
            </Typography>
            <Divider sx={{mt: 0.5, mb: 3, borderColor: "#ff7321", borderBottomWidth: 2}}/>
            <Box style={{display: "flex", flexWrap: "wrap", float: "left", width: "100%"}}>
                {getInfoBoxFields(data, content, objectId, source, historyChanges)}
            </Box>
        </Box>
    );
}

export function ViewAssetDetails({
    type,
    objectId,
}: ViewAssetDetailsProps): JSX.Element {
    const {dataLoaded, initialData, vendor} = useViewState();

    if(objectId === "") {
        return <Alert severity="error" sx={{ mb: 2 }}>ID was not provided</Alert>
    }

    if(objectId === "add") {
        if(!dataLoaded.value) {
            dataLoaded.setValue(true)
        }

        return (
            <Box>
                <Box className="viewAssetContainer">
                    <InfoBox
                        name="Asset Information"
                        data={emptyAsset}
                        content={infoBoxContent}
                        source={sourceTypeDatas[type].api}
                        objectId={objectId}
                        historyChanges={[]}
                    />
                </Box>
            </Box>
        );
    }

    const assetData = useAssetDataItem(type, objectId);
    const revisionData = useRevisionHistory(type, objectId);

    const revisionChanges = ParseRevisionData(revisionData.data);

    if(assetData.isSuccess && !dataLoaded.value) {
        dataLoaded.setValue(true)
        initialData.setValue(assetData.data[0])
        vendor.setValue(assetData.data[0].vendor)
    }

    return(
        <Box>
            {assetData.isLoading && (
                <Alert severity="info" sx={{ mb: 2 }}>Loading...</Alert>
            )}
            {assetData.isError && (
                <Alert severity="error" sx={{ mb: 2 }}>Asset data could not be loaded</Alert>
            )}
            {assetData.isSuccess && assetData.data.length === 0 && (
                <Alert severity="info" sx={{ mb: 2 }}>Requested asset could not be found</Alert>
            )}
            {assetData.isSuccess && assetData.data.length !== 0 && (
                <Box>
                    <Box className="viewAssetContainer">
                        <InfoBox
                            name="Asset Information"
                            data={assetData.data[0]}
                            content={infoBoxContent}
                            source={sourceTypeDatas[type].api}
                            objectId={objectId}
                            historyChanges={revisionChanges}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
}

function ViewAsset({type, accessToken, config, queryClient}: IHooksHOCProps): JSX.Element {
    const {pathname} = window.location;
    const parts = pathname.split("/");

    let product = -1;
    let objId: string;

    if(type === sourceTypes.Psirt) {
        // eslint-disable-next-line prefer-destructuring
        objId = parts[3]
        product = Number(parts[2]);
    }
    else {
        // eslint-disable-next-line prefer-destructuring
        objId = parts[2]
    }

    const [editing, setEditing] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [updateStatus, setUpdateStatus] = useState("none")
    const [dataLoaded, setDataLoaded] = useState(false)
    const [vendor, setVendor] = useState<DropDownItem>({"id": -1, "name": ""})
    const [status, setStatus] = useState("Unknown")
    const [initialData, setInitialData] = useState<AssetDataInfo>(emptyAsset)
    const [objectId, setObjectId] = useState(objId);

    const viewStateMemo = useMemo(() => ({
        editing: {value: editing, setValue: setEditing},
        updating: {value: updating, setValue: setUpdating},
        updateStatus: {value: updateStatus, setValue: setUpdateStatus},
        dataLoaded: {value: dataLoaded, setValue: setDataLoaded},
        vendor: {value: vendor, setValue: setVendor},
        status: {value: status, setValue: setStatus},
        initialData: {value: initialData, setValue: setInitialData},
        objectId: {value: objectId, setValue: setObjectId},
    }),[editing, updating, updateStatus, dataLoaded, vendor, status, initialData, objectId])

    useEffect(() => {
        if(objectId === "add") {
            setEditing(true);
        }
    }, [])

    useEffect(() => {
        if(objectId === undefined) {
            setObjectId(objId);
        }
    })

    const submitEvent = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        submitForm(
            objectId,
            type,
            product,
            infoBoxContent,
            status,
            accessToken,
            config,
            queryClient,
            viewStateMemo,
        );
    }

    return(
        <ViewState.Provider
            value={viewStateMemo}
        >
            {objId === "add" && updateStatus === "added" &&
                <Navigate
                    to={type === sourceTypes.Psirt ?
                        `/${sourceTypeDatas[type].client}/${product}/${objectId}` :
                        `/${sourceTypeDatas[type].client}/${objectId}`}
                    replace
                />
            }
            <Box>
                <Banner
                    firstLine={sourceTypeDatas[type].name}
                    secondLine={
                        objectId === "add" && updateStatus !== "added" ? "Add New Asset" : "View & Edit"
                    }
                />
                <Container maxWidth="xl">
                    <form
                        id="editForm"
                        onSubmit={submitEvent}>
                        <Box style={{ float: "right" }}>
                            {objectId !== "add" && (
                                <CancelButton
                                    editing={editing}
                                    updating={updating}
                                    onClick={() => {
                                        setEditing(false);
                                    }}
                                />
                            )}
                            <EditButton
                                requiredRoles={sourceTypeDatas[type].roles}
                                editing={editing}
                                updating={updating}
                                enabled={dataLoaded}
                            />
                        </Box>
                        <Box className="clearBoth">
                            <UploadStatusMessage status={updateStatus}/>
                        </Box>
                        <Box>
                            <ViewAssetDetails type={type} objectId={objId}/>
                        </Box>
                    </form>
                </Container>
            </Box>
        </ViewState.Provider>
    );
}

export default withHooksHOC(ViewAsset);
