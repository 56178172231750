export function validateMacAddress(mac: string): boolean {
    // If string is empty, don't validate it
    if(!mac) return true;

    /* MAC addresses can either be separated into 6 sets of 2 hexadecimal digits separated by
     * colon (:) or dash (-), or 3 sets of 4 hexadecimal digits separated by a dot (.)
     */

    let regex = /^[0-9a-f]{2}[:-][0-9a-f]{2}[:-][0-9a-f]{2}[:-][0-9a-f]{2}[:-][0-9a-f]{2}[:-][0-9a-f]{2}$/i

    let result = regex.test(mac)

    if (!result) {
        regex = /^[0-9a-f]{4}[.][0-9a-f]{4}[.][0-9a-f]{4}$/i

        result = regex.test(mac)
    }

    return result
}

export enum sourceTypes {
    UNIC = 0,
    AssetScan = 1,
    SES = 2,
    ESO = 3,
    Plantnet = 4,
    PCS = 5,
    SCR = 6,
    sWOIS = 7,
    WDCU = 8,
    Psirt = 9,
}

export const sourceTypeDatas: {[key in sourceTypes]: any} = {
    [sourceTypes.UNIC]: {
        "name": "Module Scan Data",
        "client": "modules",
        "api": "modules",
        "readonly_roles": ["otam_admin", "otam_engine_data", "otam_engine_data_read"],
        "roles": ["otam_admin", "otam_engine_data"],
    },
    [sourceTypes.AssetScan]: {
        "name": "Asset Scan Data",
        "client": "asset_scan",
        "api": "asset_scan",
        "readonly_roles": ["otam_admin", "otam_asset_scan_data", "otam_asset_scan_data_read"],
        "roles": ["otam_admin", "otam_asset_scan_data"],
    },
    [sourceTypes.SES]: {
        "name": "SES Asset Data",
        "client": "ses",
        "api": "ses",
        "readonly_roles": ["otam_admin", "otam_ses_data", "otam_ses_data_read"],
        "roles": ["otam_admin", "otam_ses_data"],
    },
    [sourceTypes.ESO]: {
        "name": "ES&O Asset Data",
        "client": "eso",
        "api": "eso",
        "readonly_roles": ["otam_admin", "otam_eso_data", "otam_eso_data_read"],
        "roles": ["otam_admin", "otam_eso_data"],
    },
    [sourceTypes.Plantnet]: {
        "name": "Plantnet Asset Data",
        "client": "plantnet",
        "api": "plantnet",
        "readonly_roles": ["otam_admin", "otam_plantnet_data", "otam_plantnet_data_read"],
        "roles": ["otam_admin", "otam_plantnet_data"],
    },
    [sourceTypes.PCS]: {
        "name": "PCS Asset Data",
        "client": "pcs",
        "api": "pcs",
        "readonly_roles": ["otam_admin", "otam_pcs_data", "otam_pcs_data_read"],
        "roles": ["otam_admin", "otam_pcs_data"],
    },
    [sourceTypes.SCR]: {
        "name": "SCR Asset Data",
        "client": "scr",
        "api": "scr",
        "readonly_roles": ["otam_admin", "otam_scr_data", "otam_scr_data_read"],
        "roles": ["otam_admin", "otam_scr_data"],
    },
    [sourceTypes.sWOIS]: {
        "name": "Marine sWOIS Asset Data",
        "client": "swois",
        "api": "swois",
        "readonly_roles": ["otam_admin", "otam_swois_data", "otam_swois_data_read"],
        "roles": ["otam_admin", "otam_swois_data"],
    },
    [sourceTypes.WDCU]: {
        "name": "WDCU Asset Data",
        "client": "wdcu",
        "api": "wdcu",
        "readonly_roles": ["otam_admin", "otam_wdcu_data", "otam_wdcu_data_read"],
        "roles": ["otam_admin", "otam_wdcu_data"],
    },
    [sourceTypes.Psirt]: {
        "name": "Registered Product Assets",
        "client": "asset_groups",
        "api": "asset_groups",
        "readonly_roles": ["otam_admin", "otam_psirt"],
        "roles": ["otam_admin", "otam_psirt"],
    },
}
