import axios from 'axios';

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuth } from '../auth/auth.hooks';
import {OtamProduct} from "./upload.types";

const specialChars: Record<string, string> = {
    "Ã„": "Ä",
    "Ã¤": "ä",
    "Ã–": "ö",
    "Ã¶": "Ö",
    "Ã…": "å",
    "Ã¥": "Å",
}

const reformat = false;

function remapString(input: string): string {
    const re = new RegExp(Object.keys(specialChars).join("|"), "gi")
    return input.replace(re, (matched) => specialChars[matched]);
}

export const useProducts = (): UseQueryResult<OtamProduct[]> => {
    const { accessToken, config } = useAuth();

    const data =  useQuery({
        queryKey: ['upload-products'],
        queryFn:() =>
            axios
                .get<OtamProduct[]>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/upload/products`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}`}
                    }
                )
                .then((response) => response.data),
        
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    )

    // FastAPI mangles some special characters, so fix them here
    // To my knowledge this only happens when running on Windows
    // Let's try this in dev first and if this does happen there,
    // we can enable this
    if(reformat) {
        if(data.data !== undefined) {
            for(let i = 0; i < data.data.length; i += 1) {
                Object.keys(data.data[i]).forEach((key) => {
                    switch(typeof (data.data[i][key])) {
                        case "string": {
                            const str = data.data[i][key] as string
                            data.data[i][key] = remapString(str)
                            break;
                        }

                        case "object": {
                            const obj = data.data[i][key] as string[]
                            for(let j = 0; j < obj.length; j += 1) {
                                obj[j] = remapString(obj[j])
                            }
                            data.data[i][key] = obj;
                            break;
                        }

                        default:
                            break;
                    }
                })
            }
        }
    }

    return data
}
