import React from "react";
import { UseQueryResult } from "@tanstack/react-query";

import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { CSVLink } from "react-csv";
import { GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid";

export const withHooksHOC = (Component: React.ElementType) => function withHooks({dataQuery, columnConfig, columnVisibilityModel, exportName}: ExportViewProps): JSX.Element {
    return <Component
            dataQuery={dataQuery}
            columnConfig={columnConfig}
            columnVisibilityModel={columnVisibilityModel}
            exportName={exportName}
            />;
};

interface ExportViewProps {
    dataQuery: UseQueryResult<any>;
    columnConfig: GridColDef[];
    columnVisibilityModel?: GridColumnVisibilityModel;
    exportName?: string;
}

interface IHooksHOCProps {
    dataQuery: UseQueryResult<any>;
    columnConfig: GridColDef[];
    columnVisibilityModel?: GridColumnVisibilityModel;
    exportName?: string;
}

interface ExportDialogState {
    panelOpen: boolean;
    columns: string;
}

interface ExportHeaders {
    label: string;
    key: string;
}

class ExportDialog extends React.Component<IHooksHOCProps, ExportDialogState> {
    
    constructor(props: IHooksHOCProps) {
        super(props);
        this.state = {
            panelOpen: false,
            columns: "all"
        }
        this.handleClose = this.handleClose.bind(this);
    }


    handleClose = (): void  => {
        this.setState({
            panelOpen: false,
        })
    };    
    
    Actions(headers: ExportHeaders[]): JSX.Element{
        const {dataQuery} = this.props;
        const {exportName} = this.props;
        const filename= `${exportName}.csv`;
        const handleStartExport = ():void => {
            document.getElementById("CSVLink")?.click()
          };
        
        return(
            <Box className="dialogButtonBar">
                <Box>
                    <Button
                        style={{ color: "#ffffff", backgroundColor: "#0f334a"}}
                        onClick={this.handleClose}>Close</Button>
                </Box>
                {dataQuery !== undefined && !dataQuery.isLoading && !dataQuery.isError && (
                    <Box>
                        {dataQuery.data && dataQuery.data !== null && (
                                <Box>
                                    <Button
                                        onClick={handleStartExport}
                                        style={{ color: "#ffffff", backgroundColor: "#0f334a"}}
                                        >Export</Button>

                                    <CSVLink
                                    data={exportName === "Internal Sites" ? dataQuery.data.sites : dataQuery.data}
                                    filename={filename}
                                    className="hidden"
                                    target="_blank"
                                    headers={headers}
                                    id="CSVLink"
                                    />
                                </Box>
                            
                        )}
                    </Box>
                )}
            </Box>
        );
    }

    
    DialogContent(): JSX.Element{
        const {columns} = this.state;
        const {dataQuery} = this.props;
        
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>):void => {
            this.setState({
                columns: (event.target as HTMLInputElement).value
            })
          };

        return(
            <Box>
                {dataQuery.isLoading && (
                    <Alert severity="info" sx={{ mb: 2, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>Loading export data</Alert>
                )}
                {dataQuery !== undefined && dataQuery.isError && (
                    <Alert severity="error" sx={{ mb: 2 }}>Something went wrong while loading backend status</Alert>
                )}
                {dataQuery !== undefined && !dataQuery.isError && (
                    <FormControl>
                    <FormLabel id="export-settings">Export Settings</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="included-columns-radio-buttons-group"
                        name="included-columns-controlled-radio-buttons-group"
                        value={columns}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="all" control={<Radio />} label="Export all columns" />
                        <FormControlLabel value="visible" control={<Radio />} label="Export visible columns" />
                    </RadioGroup>
                </FormControl>
                )}
            </Box>
        );
    }

    
   // Render the page
    render(): JSX.Element {
    const {panelOpen} = this.state;
    const {columns} = this.state;
    const {columnConfig} = this.props;
    const {columnVisibilityModel} = this.props;
    const {exportName} = this.props;
    const headers: ExportHeaders[] =[];
    const title = `Export ${exportName}`
    for(let i = 0; i < columnConfig.length; i += 1) {
        // eslint-disable-next-line no-continue
        if(columnConfig[i].field === "__check__" || columnConfig[i].field === "actions" || columnConfig[i].field === "object_id") continue;
        
        if (columns === "visible") {
            if (columnVisibilityModel !== undefined && columnConfig[i].field in columnVisibilityModel) {
                // eslint-disable-next-line no-continue
                if (!columnVisibilityModel[columnConfig[i].field]) continue;
            }
        }
        
        headers.push({label: columnConfig[i].field, key: columnConfig[i].field})
    }

    const handleOpen = ():void => {
        this.setState({
            panelOpen: true,
        })
      };
      return(
        <Box>
            <Button
                onClick={handleOpen}>
                    <Typography style={{fontWeight: "normal", fontSize: 14,}}>Export</Typography>
                </Button>
            <Dialog fullWidth
                maxWidth="sm"
                open={panelOpen}>
                <DialogTitle>
                    <Typography variant="h1">{title}</Typography>
                </DialogTitle>
                <DialogContent>
                    {this.DialogContent()}
                </DialogContent>
                <DialogActions>
                    {this.Actions(headers)}
                </DialogActions>
            </Dialog>
        </Box>
);
    }

}
export default withHooksHOC(ExportDialog);
