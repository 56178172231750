import './styles.index.scss';

import axios from 'axios';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import * as React from 'react';
import {useMemo} from 'react';
import * as ReactDOM from 'react-dom/client';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';

import {AccountInfo, Configuration, InteractionType, PublicClientApplication,} from '@azure/msal-browser';
import {MsalAuthenticationResult, MsalAuthenticationTemplate, MsalProvider, useMsal,} from '@azure/msal-react';
import {Box, CircularProgress, createTheme, ThemeProvider} from "@mui/material";
import {datadogRum} from '@datadog/browser-rum';
import {PrivateRoutes} from "./features/navigation/privateRoutes";

import * as serviceWorker from './serviceWorker';
import {Navigation, allRoles} from "./features/navigation/navigation.component";
import {Engines} from "./features/engines/engines.component";
import {AssetScanData} from "./features/assetScan/assetScan.component";
import ViewAssetScan from "./features/assetScan/viewAssetScan/viewAssetScan.component";
import {Home} from "./features/home/home.component";
import ViewEngine from "./features/engines/viewEngine/viewEngine.component";
import {Ses} from "./features/ses/ses.component";
import {Eso} from "./features/eso/eso.component";
import ViewEso from "./features/eso/viewEso/viewEso.component";
import {Installation} from "./features/installation/installation.component";
import {ShowInstallation} from "./features/installation/viewInstallation/viewInstallation.component";
import {useAuth} from "./features/auth/auth.hooks";
import {AssetGroups} from "./features/assetGroups/assetGroups.component";
import {AssetGroupDetailsView} from "./features/assetGroups/viewGroupDetails/viewGroupDetails.component";
import {Plantnet} from "./features/plantnet/plantnet.component";
import ViewPlantnet from "./features/plantnet/viewPlantnet/viewPlantnet.component";
import {Scr} from "./features/scr/scr.component";
import ViewSes from "./features/ses/viewSes/viewSes.component";
import ViewScr from "./features/scr/viewScr/viewScr.component";
import {Equipment} from "./features/installation/viewEquipment/viewEquipment.component";
import {InternalSites} from "./features/internalSites/sites.component";
import {ViewInternalSite} from "./features/internalSites/viewSite/viewSite.component";
import {Validation} from "./features/validation/validation.component";
import ViewPatch from './features/validation/viewValidation/viewValidation.component';
import {Wdcu} from "./features/wdcu/wdcu.component";
import ViewWdcu from "./features/wdcu/viewWdcu/viewWdcu.component";
import ViewSiteAsset from "./features/internalSites/viewSiteAsset/viewSiteAsset.component";
import {Upload} from './features/upload/upload.component';
import {FieldRevisions} from "./features/fieldRevision/fieldRevision.component";
import {InternalAssets} from "./features/internalSites/internalAssets/internalAssets.component";
import {Pcs} from "./features/pcs/pcs.component";
import ViewPcs from "./features/pcs/viewPcs/viewPcs.component";
import {Swois} from "./features/swois/swois.component";
import ViewSwois from "./features/swois/viewSwois/viewSwois.component";
import ViewAsset from "./features/assetData/viewAsset/viewAsset.component";
import {sourceTypes} from "./features/misc";

const queryClient = new QueryClient();

const theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: "Noto Sans",
                    fontSize: 16,
                    fontWeight: "lighter",
                },
                h1: {
                    fontSize: 20,
                    fontWeight: "normal",
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    fontFamily: "Noto Sans",
                    '& input': {
                        fontFamily: "Noto Sans",
                        fontSize: 16,
                        fontWeight: "lighter",
                    },
                    '& label.Mui-focused': {
                        // Label when selected
                        color: '#555555',
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: '#cccccc',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            // Not selected and not hovering over
                            borderColor: '#cccccc',
                        },
                        '&:hover fieldset': {
                            // Hovering over
                            borderColor: '#cccccc',
                        },
                        '&.Mui-focused fieldset': {
                            // Selected
                            borderWidth: "1px",
                            borderColor: '#cccccc',
                        },
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    fontFamily: "Noto Sans",
                    fontSize: 16,
                    fontWeight: "lighter",
                    color: "#ffffff",
                    '& .MuiAlert-icon': {
                        fontSize: 25,
                        color: "#ffffff",
                    }
                },
                standardSuccess: {
                    backgroundColor: "#a4d596",
                },
                standardInfo: {
                    backgroundColor: "#ffaa7a",
                },
                standardError: {
                    backgroundColor: "#e66969",
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "Noto Sans",
                    fontSize: 14,
                    fontWeight: "normal",
                    color: "#000000",
                    '&:hover': {
                        backgroundColor: "#e6696900",
                    }
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    backgroundColor: "#00000000",
                },
                track: {
                    "&.Mui-disabled": {
                        color: "#e886a9"
                    },
                    "&.Mui-checked": {
                        color: "#95cc97"
                    },
                    "&.Mui-checked + .MuiSwitch-track": {
                        backgroundColor: "#4CAF50",
                    }
                },
                colorPrimary: {
                    "&.Mui-checked": {
                        color: "#000000",
                    },
                    "&.Mui-checked + .MuiSwitch-track": {
                        backgroundColor: "#4CAF50",
                    },
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: "Noto Sans",
                    fontSize: 16,
                    fontWeight: "lighter",
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {

                }
            }
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    fontFamily: "Noto Sans",
                    fontSize: 16,
                    fontWeight: "lighter",
                },
            }
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    fontFamily: "Noto Sans",
                    fontSize: 14,
                    fontWeight: "lighter",
                    borderBottom: "none",
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: "Noto Sans",
                    fontSize: 14,
                    fontWeight: "lighter",
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {

                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {

                }
            }
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1825,  // Edit this to change the <Container maxWidth="xl"> width
        },
    }
})

function AuthStatusScreen({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return <div className="auth__screen">{children}</div>;
}

function AuthError({
  error,
}: React.PropsWithChildren<MsalAuthenticationResult>): JSX.Element {
  return <AuthStatusScreen>
    <div className="auth__error-title">An Error Occurred</div>

    {error?.errorMessage ? (
      <div className="auth__error-message">{error.errorMessage}</div>
    ) : null}
  </AuthStatusScreen>
}

function AuthLoading(): JSX.Element {
  return <AuthStatusScreen>
    <div>Loading</div>
  </AuthStatusScreen>
}

export type IAuthContext = {
  accessToken: string;
  account: AccountInfo;
  config: Record<string, unknown>;
  setToken: (token: string) => void;
};

export const AuthContext = React.createContext<IAuthContext>({
    // This function gets overridden by "setToken" in "AuthProvider" when "useMemo" is set up.
    // We need to disable few errors here, because eslint doesn't like empty functions.
    // We can't set the actual content of the function here, because "setAccessToken" isn't
    // available in this context/scope.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
    setToken(token: string): void {},
    accessToken: '',
  config: {},
  account: {
    name: '',
    username: '',
    tenantId: '',
    environment: '',
    homeAccountId: '',
    idTokenClaims: {},
    localAccountId: '',
  }
});

axios
  .get('/config.json')
  .then((res) => {
    const config = res.data;

    const authConfiguration: Configuration = {
      auth: {
        redirectUri: '/',
        clientId: config.REACT_APP_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${config.REACT_APP_TENANT_ID}`,
      },
      cache: {
        cacheLocation: 'localStorage',
      },
    };

    const authInstance = new PublicClientApplication(authConfiguration);

    function AuthProvider({
      children,
    }: {
      children: React.ReactNode;
    }): JSX.Element {
      const auth = useMsal();
      const [account] = auth.instance.getAllAccounts();
      const [rumInitiated, setRum] = React.useState(false);
      const [accessToken, setAccessToken] = React.useState('');

      const setToken = (token: string): void => {
          setAccessToken(token);
      }

      React.useEffect(() => {
        if (!rumInitiated) {
          const DATADOG_SITE = 'datadoghq.eu';
          const DATADOG_SERVICE = 'react-template';
          const DATADOG_PROXY_HOST = 'https://datadog-proxy.app.wartsila.com';
          const DATADOG_APP_ID = '48ef302d-d3cc-4425-82a1-5bf7349634e3';
          const DATADOG_CLIENT_TOKEN = 'pubfa91e9b08ba9f99334515ef346ff9fed';

          datadogRum.init({
            traceSampleRate: 100,
            site: DATADOG_SITE,
            trackUserInteractions: true,
            service: DATADOG_SERVICE,
            applicationId: DATADOG_APP_ID,
            proxy: DATADOG_PROXY_HOST,
            clientToken: DATADOG_CLIENT_TOKEN,
            env: config.REACT_APP_ENVIRONMENT,
            version: config.REACT_APP_VERSION,
            allowedTracingUrls: [config.REACT_APP_API_BASE_URL],
          });

          datadogRum.setUser({
            name: account.name,
            email: account.username,
          });

          setRum(true);
        }
      }, [rumInitiated, account]);

      React.useEffect(() => {
        if (!accessToken) {
          auth.instance
            .acquireTokenSilent({
              account,
              scopes: [`${config.REACT_APP_API_CLIENT_ID}/.default`],
            })
            .then((accountInformation) =>
              setAccessToken(accountInformation.accessToken)
            );
        }
      }, [accessToken]);

      const value = useMemo(() => ({ accessToken, account, config, setToken }), [accessToken, account, config]);
      return (
        <AuthContext.Provider value={value}>
          {children}
        </AuthContext.Provider>
      );
    }

    function AppContent(): JSX.Element {
        return(
            <Router>
                    <Navigation/>
                    <Routes>
                        <Route path="" element={<Home/>}/>
                        <Route element={
                            <PrivateRoutes requiredRoles={allRoles}/>}
                        >
                            <Route path="/installations" element={<Installation/>}/>
                            <Route path="/installations/:param" element={<ShowInstallation/>}/>
                            <Route path="/equipment/:param" element={<Equipment/>}/>
                        </Route>
                        <Route element={<PrivateRoutes requiredRoles={["otam_admin", "otam_engine_data", "otam_engine_data_read"]}/>}>
                            <Route path="/engine_data" element={<Engines/>}/>
                            <Route path="/engine_data/:param" element={<ViewEngine/>}/>
                            <Route path="/history/:source/:param" element={<FieldRevisions/>}/>
                        </Route>
                        <Route element={<PrivateRoutes requiredRoles={["otam_admin", "otam_asset_scan_data", "otam_asset_scan_data_read"]}/>}>
                            <Route path="/asset_scan_data" element={<AssetScanData/>}/>
                            <Route path="/asset_scan_data/:param" element={<ViewAssetScan/>}/>
                        </Route>
                        <Route element={<PrivateRoutes requiredRoles={["otam_admin", "otam_eso_data", "otam_eso_data_read"]}/>}>
                            <Route path="/eso_data" element={<Eso/>}/>
                            <Route path="/eso_data/:param" element={<ViewEso/>}/>
                        </Route>
                        <Route element={<PrivateRoutes requiredRoles={["otam_admin", "otam_ses_data", "otam_ses_data_read"]}/>}>
                            <Route path="/ses_data" element={<Ses/>}/>
                            <Route path="/ses_data/:param" element={<ViewSes/>}/>
                        </Route>
                        <Route element={<PrivateRoutes requiredRoles={allRoles}/>}>
                            <Route path="/asset_groups" element={<AssetGroups/>}/>
                            <Route path="/asset_groups/:group" element={<AssetGroupDetailsView/>}/>
                            <Route path="/asset_groups/:group/:param" element={<ViewAsset type={sourceTypes.Psirt}/>}/>
                        </Route>
                        <Route element={<PrivateRoutes requiredRoles={["otam_admin", "otam_patch_validation"]}/>}>
                            <Route path="/validation" element={<Validation/>}/>
                            <Route path="/validation/:param" element={<ViewPatch/>}/>
                        </Route>
                        <Route element={<PrivateRoutes requiredRoles={["otam_admin", "otam_upload"]}/>}>
                            <Route path="/upload" element={<Upload/>}/>
                        </Route>
                        <Route element={<PrivateRoutes requiredRoles={["otam_admin", "otam_plantnet_data", "otam_plantnet_data_read"]}/>}>
                            <Route path="/plantnet" element={<Plantnet/>}/>
                            <Route path="/plantnet/:param" element={<ViewPlantnet/>}/>
                        </Route>
                        <Route element={<PrivateRoutes requiredRoles={["otam_admin", "otam_scr_data", "otam_scr_data_read"]}/>}>
                            <Route path="/scr_data" element={<Scr/>}/>
                            <Route path="/scr_data/:param" element={<ViewScr/>}/>
                        </Route>
                        <Route element={<PrivateRoutes requiredRoles={["otam_admin", "otam_swois_data", "otam_swois_data_read"]}/>}>
                            <Route path="/swois_data" element={<Swois/>}/>
                            <Route path="/swois_data/:param" element={<ViewSwois/>}/>
                        </Route>
                        <Route element={<PrivateRoutes requiredRoles={["otam_admin", "otam_wdcu_data", "otam_wdcu_data_read"]}/>}>
                            <Route path="/wdcu_data" element={<Wdcu/>}/>
                            <Route path="/wdcu_data/:param" element={<ViewWdcu/>}/>
                        </Route>
                        <Route element={<PrivateRoutes requiredRoles={["otam_admin", "otam_pcs_data", "otam_pcs_data_read"]}/>}>
                            <Route path="/pcs_data" element={<Pcs/>}/>
                            <Route path="/pcs_data/:param" element={<ViewPcs/>}/>
                        </Route>
                        <Route element={<PrivateRoutes requiredRoles={["otam_admin", "otam_internal", "otam_internal_read"]}/>}>
                            <Route path="/internal_sites" element={<InternalSites/>}/>
                            <Route path="/internal_sites/:site" element={<ViewInternalSite/>}/>
                            <Route path="/internal_assets" element={<InternalAssets/>}/>
                        </Route>
                        <Route element={<PrivateRoutes requiredRoles={["otam_admin", "otam_internal", "otam_internal_read"]}/>}>
                            <Route path="/internal_sites/:site/view/:param" element={<ViewSiteAsset/>}/>
                        </Route>
                    </Routes>
            </Router>
        );
    }

    function HasAuth(): JSX.Element {
        const auth = useAuth();

        if(auth.accessToken !== "") {
            return <AppContent/>
        }

        return (
            <Box style={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "100vh"}}>
                <CircularProgress/>
            </Box>
        );
    }

    // Updated render to React 18 method (the old style is deprecated and forced React to run in 17 version)
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <MsalProvider instance={authInstance}>
                    <MsalAuthenticationTemplate
                        errorComponent={AuthError}
                        loadingComponent={AuthLoading}
                        interactionType={InteractionType.Redirect}>
                        <QueryClientProvider client={queryClient}>
                            <AuthProvider>
                                <HasAuth/>
                            </AuthProvider>
                            {/* Only render dev tools when node environment is not "prod" or "production" */}
                            {!process.env.NODE_ENV.startsWith("prod") && (<ReactQueryDevtools initialIsOpen={false} />)}
                        </QueryClientProvider>
                    </MsalAuthenticationTemplate>
                </MsalProvider>
            </ThemeProvider>

        </React.StrictMode>
    );

    serviceWorker.unregister();
  })
  .catch();
