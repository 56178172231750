import {useQuery, UseQueryResult} from "@tanstack/react-query";
import axios from "axios";
import {GridSortModel} from "@mui/x-data-grid";
import {ScrInfo} from "./scr.types";
import {useAuth} from "../auth/auth.hooks";

export const useScrInfo = (
    limit: number,
    page: number,
    search: string[] | undefined,
    instId: string | undefined,
    sort: GridSortModel | undefined,
    columnFilter: object | undefined
): UseQueryResult<ScrInfo[]> => {
    const { accessToken, config } = useAuth();

    let sortField: string | undefined;
    let sortDir: number | undefined;

    if(sort !== undefined && sort.length > 0) {
        sortField = sort[0].field;
        sortDir = sort[0].sort === "asc" ? 1 : -1;
    }

    return useQuery({
        queryKey: ['scr_m', { 'limit': limit, 'page': page, 'search': search, 'inst_id': instId, 'sort': sort }, columnFilter],
        queryFn:() =>
            axios
                .get<ScrInfo[]>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/scr_info`,
                    { params:{
                            limit,
                            page,
                            ...(search !== undefined ? { search: String(search) } : {}),
                            ...(instId && {inst_id: instId}),
                            ...(sortField !== undefined ? { sort: sortField } : {}),
                            ...(sortDir !== undefined ? { sort_dir: sortDir } : {}),
                            ...((columnFilter !== undefined && Object.keys(columnFilter).length > 0) ? {column_filter: columnFilter} : {})
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}

export const useScrItem = (id: string): UseQueryResult<ScrInfo[]> => {
    const { accessToken, config } = useAuth();

    return useQuery({
        queryKey: ['scr_s', { 'id': id }],
        queryFn:() =>
            axios
                .get<ScrInfo[]>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/scr_info`,
                    { params: {
                            object_id: id
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}

export const useScrInfoCount = (
    search: string[] | undefined,
    instId: string | undefined,
    columnFilter: object | undefined
): UseQueryResult<number> => {
    const { accessToken, config } = useAuth();

    return useQuery({
        queryKey: ['scr_rows', { 'search': search, 'inst_id': instId }, columnFilter],
        queryFn:() =>
            axios
                .get<number>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/scr_info/rows`,
                    { params:{
                            ...(search !== undefined ? { search: String(search) } : {}),
                            ...(instId && {inst_id: instId}),
                            ...((columnFilter !== undefined && Object.keys(columnFilter).length > 0) ? {column_filter: columnFilter} : {})
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}
