import {Box, Container} from "@mui/material";
import React from "react";
import {Banner} from "../../banner/banner.component";
import {sourceTypeDatas, sourceTypes} from "../../misc"
import {AssetDataGrid} from "../../assetData/assetData.component";


export function AssetGroupDetailsView(): JSX.Element {
    const {pathname} = window.location;
    const parts = pathname.split("/");
    const params = parts[2].split("+");

    const groupName = decodeURIComponent(params[0]);
    const groupId = params[1];

    return (
        <Box>
            <Banner firstLine={sourceTypeDatas[sourceTypes.Psirt].name} secondLine={`View and edit group "${groupName}"`} gridBanner/>
            <Container maxWidth="xl">
                <AssetDataGrid
                    type={sourceTypes.Psirt}
                    instId={undefined}
                    searchCacheKey="group_assets"
                    productId={groupId}
                    useColumnFilters
                    showAddAssetButton
                />
            </Container>
        </Box>
    )
}
